/* eslint-disable no-underscore-dangle */
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const useAuthState = create(
  persist(
    (set, get) => ({
      user: {},
      token: null,
      isLoggedIn: () => !!get().token,
      logIn: data => {
        set(() => ({ token: data.token }))
        set(() => ({ user: data.user }))
      },
      logOut: () => {
        set(() => ({ token: null }))
        set(() => ({ user: {} }))
      },
      setUser: user => set(() => ({ user })),
      getUserId: () => get().user._id,
    }),
    {
      name: 'auth',
    },
  ),
)

export default useAuthState
