exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/aboutUs.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-agreement-js": () => import("./../../../src/pages/agreement.js" /* webpackChunkName: "component---src-pages-agreement-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bookings-jsx": () => import("./../../../src/pages/Bookings.jsx" /* webpackChunkName: "component---src-pages-bookings-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/Cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-checkout-jsx": () => import("./../../../src/pages/Checkout.jsx" /* webpackChunkName: "component---src-pages-checkout-jsx" */),
  "component---src-pages-diabetes-faq-jsx": () => import("./../../../src/pages/diabetesFaq.jsx" /* webpackChunkName: "component---src-pages-diabetes-faq-jsx" */),
  "component---src-pages-doctors-doctor-id-jsx": () => import("./../../../src/pages/doctors/[doctorId].jsx" /* webpackChunkName: "component---src-pages-doctors-doctor-id-jsx" */),
  "component---src-pages-doctors-index-jsx": () => import("./../../../src/pages/doctors/index.jsx" /* webpackChunkName: "component---src-pages-doctors-index-jsx" */),
  "component---src-pages-family-planning-jsx": () => import("./../../../src/pages/familyPlanning.jsx" /* webpackChunkName: "component---src-pages-family-planning-jsx" */),
  "component---src-pages-forgot-password-jsx": () => import("./../../../src/pages/ForgotPassword.jsx" /* webpackChunkName: "component---src-pages-forgot-password-jsx" */),
  "component---src-pages-heart-dx-faq-jsx": () => import("./../../../src/pages/heartDxFaq.jsx" /* webpackChunkName: "component---src-pages-heart-dx-faq-jsx" */),
  "component---src-pages-home-jsx": () => import("./../../../src/pages/Home.jsx" /* webpackChunkName: "component---src-pages-home-jsx" */),
  "component---src-pages-hyperacidity-faq-jsx": () => import("./../../../src/pages/hyperacidityFaq.jsx" /* webpackChunkName: "component---src-pages-hyperacidity-faq-jsx" */),
  "component---src-pages-hypertension-faq-jsx": () => import("./../../../src/pages/hypertensionFaq.jsx" /* webpackChunkName: "component---src-pages-hypertension-faq-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/Login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-medication-therapy-management-jsx": () => import("./../../../src/pages/medication-therapy-management.jsx" /* webpackChunkName: "component---src-pages-medication-therapy-management-jsx" */),
  "component---src-pages-medication-therapy-management-provider-jsx": () => import("./../../../src/pages/medication-therapy-management-provider.jsx" /* webpackChunkName: "component---src-pages-medication-therapy-management-provider-jsx" */),
  "component---src-pages-meeting-appointment-id-jsx": () => import("./../../../src/pages/meeting/[appointmentId].jsx" /* webpackChunkName: "component---src-pages-meeting-appointment-id-jsx" */),
  "component---src-pages-mental-health-jsx": () => import("./../../../src/pages/mentalHealth.jsx" /* webpackChunkName: "component---src-pages-mental-health-jsx" */),
  "component---src-pages-orders-jsx": () => import("./../../../src/pages/Orders.jsx" /* webpackChunkName: "component---src-pages-orders-jsx" */),
  "component---src-pages-pharmacy-jsx": () => import("./../../../src/pages/pharmacy.jsx" /* webpackChunkName: "component---src-pages-pharmacy-jsx" */),
  "component---src-pages-prescription-checkout-js": () => import("./../../../src/pages/prescription-checkout.js" /* webpackChunkName: "component---src-pages-prescription-checkout-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-privacy-policyc-js": () => import("./../../../src/pages/privacy_policyc.js" /* webpackChunkName: "component---src-pages-privacy-policyc-js" */),
  "component---src-pages-profile-jsx": () => import("./../../../src/pages/Profile.jsx" /* webpackChunkName: "component---src-pages-profile-jsx" */),
  "component---src-pages-programs-jsx": () => import("./../../../src/pages/programs.jsx" /* webpackChunkName: "component---src-pages-programs-jsx" */),
  "component---src-pages-providers-jsx": () => import("./../../../src/pages/providers.jsx" /* webpackChunkName: "component---src-pages-providers-jsx" */),
  "component---src-pages-providers-med-jsx": () => import("./../../../src/pages/providersMed.jsx" /* webpackChunkName: "component---src-pages-providers-med-jsx" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/Register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-remote-patient-monitoring-jsx": () => import("./../../../src/pages/remote-patient-monitoring.jsx" /* webpackChunkName: "component---src-pages-remote-patient-monitoring-jsx" */),
  "component---src-pages-reset-jsx": () => import("./../../../src/pages/Reset.jsx" /* webpackChunkName: "component---src-pages-reset-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms_of_use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-upload-prescription-jsx": () => import("./../../../src/pages/upload-prescription.jsx" /* webpackChunkName: "component---src-pages-upload-prescription-jsx" */),
  "component---src-pages-upload-request-jsx": () => import("./../../../src/pages/upload-request.jsx" /* webpackChunkName: "component---src-pages-upload-request-jsx" */),
  "component---src-pages-userprofile-jsx": () => import("./../../../src/pages/userprofile.jsx" /* webpackChunkName: "component---src-pages-userprofile-jsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/Product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-search-cat-js": () => import("./../../../src/templates/searchCat.js" /* webpackChunkName: "component---src-templates-search-cat-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-service-jsx": () => import("./../../../src/templates/Service.jsx" /* webpackChunkName: "component---src-templates-service-jsx" */),
  "component---src-templates-store-js": () => import("./../../../src/templates/store.js" /* webpackChunkName: "component---src-templates-store-js" */)
}

